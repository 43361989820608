import React from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import { graphql, PageProps, withPrefix } from "gatsby";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { ImageInterface, LocalizedContextInterface, SanityColorList, Seo } from "../../types/SanityTypes";
import HeroBanner from "../../components/HeroBanner";
import { event16 } from "../../analytics/event16";

type StoreLocatorQueryProps = {
  sanityStoreLocator: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      heroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    iFrameId: string;
    iFrameScript: string;
    iFrameUrl: string;
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type StoreLocatorPageContext = LocalisedPageContext;

export const query = graphql`
  query StoreLocatorData($_id: String, $language: String) {
    sanityStoreLocator(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...Image
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      iFrameId
      iFrameScript
      iFrameUrl
      seo {
        metaTitle
        metaDescription
      }
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const StoreLocator = (props: PageProps<StoreLocatorQueryProps, StoreLocatorPageContext>) => {
  const language = props.pageContext.language;
  const storeLocator = props.data.sanityStoreLocator;

  const { htmlLang, siteUrl } = useSiteMetadata(language);

  const pageTitle = storeLocator.banner.heading;
  const localizedPath = withPrefix(
    getLocalizedPath(props.data?.sanityStoreLocator?.slug?.current, props.data?.sanityStoreLocator?._id)
  );

  const handleIframeLoad = () => {
    event16();
  };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"storeLocator"}
      analyticsTagMeta={{ promoId: "10" }}
    >
      <StructuredData
        type={"StoreLocator"}
        data={storeLocator.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${storeLocator.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={storeLocator.seo.metaTitle}
        description={storeLocator.seo.metaDescription}
        imageUrl={storeLocator.banner._rawHeroImage.asset.url}
        imageAlt={storeLocator.banner._rawHeroImage.alt}
      />
      <div className="page_storeLocator-us">
        <HeroBanner
          image={storeLocator.banner?._rawHeroImage}
          sanityImage={storeLocator.banner?.heroImage}
          title={storeLocator.banner?.heading}
          alignment={storeLocator.banner?.contentAlignment}
          isVideoBanner={storeLocator.banner?.isVideoBanner}
          video={storeLocator.banner?._rawHeroVideo}
        />
        <Container fluid data-testid="storeLocator-us">
          <iframe
            id={storeLocator?.iFrameId}
            style={{ width: "100%", height: "1000px" }}
            src={storeLocator?.iFrameUrl}
            title={storeLocator.banner?.heading}
            onLoad={handleIframeLoad}
            frameBorder={0}
          />
        </Container>
      </div>
    </Layout>
  );
};

export default StoreLocator;
