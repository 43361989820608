import { EventType, pushEvent } from "./index";

/**
 * Tool Usage
 */
export const event16 = () => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.virtualagent,
      eventLabel: "Virtual Agent Open",
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.interest
  });
};
